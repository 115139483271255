import React, { useEffect } from 'react';
import { create as createChart } from '@amcharts/amcharts4/core';
import { PieChart, PieSeries, Legend } from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core'; // Import am4core module
// Apply the animated theme

const PieChartComponentDashboardTop5Leaderboard = ({ data, style }) => {
    // Use useEffect to create the chart when the component mounts
    useEffect(() => {
        // Create the chart instance
        let chart = createChart(document.getElementById('chartDiv'), PieChart);

        // Set data
        chart.data = data;

        // Create pie series
        let pieSeries = chart.series.push(new PieSeries());
        pieSeries.dataFields.value = 'value';
        pieSeries.dataFields.category = 'category';

        pieSeries.colors.list = [
            am4core.color("#f7d501"), // bl
            am4core.color("#18a5f0"), // gp
            am4core.color("red"), // robi
        ];

        // Add labels
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // Add legend
        chart.legend = new Legend();
        // chart.legend.position = 'right';

        // Add label bullets
        // let labelBullet = pieSeries.bullets.push(new LabelBullet());
        // labelBullet.locationX = 0.5;
        // labelBullet.locationY = 0.5;
        // // labelBullet.label.text = '{category}: {value.percent.formatNumber("#.0")}%';
        // labelBullet.label.text = '{category}: {value}';

        // Cleanup function
        return () => {
            if (chart) {
                chart.dispose();
            }
        };
    }, [data]);

    return <div id="chartDiv" style={{ width: '100%', height: '290px' }}></div>;
};

export default PieChartComponentDashboardTop5Leaderboard;
