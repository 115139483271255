import React, {Fragment} from 'react'
import ReactHtmlParser from 'react-html-parser';

function MultiColor(props) {

    const badge_colors = ['badge-light-primary', 'badge-light-info', 'badge-light-success', 'badge-light-danger' ,  'badge-light-warning', ]
    function getBadgeRandom(value){
        var badge = badge_colors[Math.floor(Math.random()*badge_colors.length)]
        return `<span class="badge ${badge} fs-7 m-1">${value}</span>`;
    }

    function getBadgeSerialListing(value, index) {
        var badge = badge_colors[index%badge_colors.length]
        return `<span class="badge ${badge} fs-7 m-1">${value}</span>`;
    }

    return (
        <Fragment>
            {
                props?.dataList ?
                    props?.dataList?.map((value, i) => {
                        if (props.listingtype && props.listingtype==='serial') {
                            return ReactHtmlParser(getBadgeSerialListing(value, i))
                        }
                        else{
                            return ReactHtmlParser(getBadgeRandom(value))
                        }
                    })
                : null
            }
        </Fragment>
    )
}

export default MultiColor

// usage steps
// import MultiColorBadges  from '../components/Badges/MultiColor';

{/* <MultiColorBadges dataList={row.permissions.split(', ')}/> */}
{/* <MultiColorBadges listingtype='serial' dataList={row.permissions.split(', ')}/> */}

// listingtype = serial |