import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { kt_aside_mobile_toggle } from './Helpers/DesignHelpers'
import HeaderUserLayout from './HeaderUserLayout'
import useWindowDimensions from './Hooks/useWindowDimensions'
import useWindowPosition from './Hooks/useWindowPosition'


function Header(props) {
  useEffect(() => {
    document.title = props?.breadcrumb?.pageTitle || "Vista Campaign"
  }, [props?.breadcrumb]);


  const { height, width } = useWindowDimensions();
  const scrollPosition = useWindowPosition();



  return (
    <Fragment>
      <div id="kt_header" className="header align-items-stretch" >

        <div className="header-brand border-bottom-1 w-desktop-73px px-0">
          <Link to="/" className="mx-lg-auto d-desktop-none ms-5" id="header-logo">
            <img
              alt="Logo"
              src={`${process.env.PUBLIC_URL}/assets/media/logos/logo.png`}
              className="h-lg-50px h-40px "
            />
          </Link>

          <div
            className="d-flex align-items-center ms-n3 me-1"
            title="Show aside menu"
          >
            {/* d-lg-none */}
            <div
              className="btn btn-icon btn-active-color-primary w-30px h-30px ms-8 "
              id="kt_aside_mobile_toggle"
              onClick={kt_aside_mobile_toggle}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>



            {
              width < 992 ?
                <HeaderUserLayout />
                : null
            }


          </div>

        </div>


        <div className="toolbar mb-sm-10 mb-xl-0 mb-10">
          <div className="container-fluid py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between ms-desktop-73px" id="top-navbar-middle-container">
            <div className="page-title d-flex flex-column me-5">
              <h1 className="d-flex flex-column text-dark fw-bolder fs-2 mb-0">
                {props?.breadcrumb?.pageTitle}
              </h1>
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 pt-1">
                {/* <li className="breadcrumb-item text-muted">
                  <Link to="/" className="text-muted text-hover-primary">
                    Home
                  </Link>
                </li> */}

                {
                  props?.breadcrumb?.layers?.map((layer, i) => {
                    return (
                      <Fragment key={'layer-' + i}>
                        {
                          i > 0 ?
                            <li className="breadcrumb-item">
                              <span className="bullet bg-gray-300 w-5px h-2px" />
                            </li> : null
                        }
                        <li className="breadcrumb-item text-muted">

                          {
                            layer?.link ?
                              <Link to="/" className={'text-muted text-hover-primary'} >
                                {layer?.title}
                              </Link>
                              : <span className={(layer?.default == 1 ? 'text-dark' : 'text-muted')}>{layer?.title}</span>
                          }
                        </li>
                      </Fragment>
                    )
                  })
                }

              </ul>
            </div>
          </div>

          <div className="d-flex align-items-center pt-3 pt-sm-0 ">
            {
              width > 991 ?
                <HeaderUserLayout />
                : null
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  roles: state.roles,
  breadcrumb: state.breadcrumb,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
