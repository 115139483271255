import axios from "axios";
import { store } from '../redux/store'
import { USER_LOGOUT } from '../redux/action'



axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
console.log('===========', process.env.REACT_APP_API_BASE_URL)

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.common['source'] = ''

export const getCall = async (query, token = null, lan = 'en') => {
    try {
        let res = await axios.get(query, {
            headers: {
                Authorization: token ? `Bearer ${token}` : "",
                lang: lan,
            },
        })
        if (res?.data?.code === 401) {
            localStorage.removeItem('user')
            localStorage.removeItem('roles')
            localStorage.removeItem('permissions')
            store.dispatch(USER_LOGOUT())
            return res?.data
        } else {
            return res?.data;
        }

    } catch (error) {
        console.log("error", error);
        return {};
    }
};

export const postCall = async (path, data, token = null, lan = "en") => {
    try {
        let res = await axios.post(path, data, {
            headers: {
                Authorization: token ? `Bearer ${token}` : "",
                lang: lan
            },
        });
        if (res?.data?.code === 401) {
            localStorage.removeItem('user')
            localStorage.removeItem('roles')
            localStorage.removeItem('permissions')
            store.dispatch(USER_LOGOUT())
            logout_cleaner()
            return res?.data
        } else {
            return res?.data;
        }

    } catch (error) {
        console.log("error", error);
        return {};
    }
};

const logout_cleaner = () => {
    let backdrops = document.querySelectorAll('.modal-backdrop')
    backdrops.forEach(element => {
        element.remove()
    });
}