import React, { Fragment, useState, useEffect } from 'react'
import { postCall } from '../api/apiService'
import { LOGIN } from '../api/apiPath'
import { SET_USER_DATA } from '../redux/action'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { Formik, useFormik, Form, Field, ErrorMessage } from 'formik';
import TextErrorMessage2 from '../components/Error/TextErrorMessage2'
import * as Yup from 'yup';
import { createAuditLog, userAgent } from '../components/Helpers/CommonHelpers'

function Login(props) {

    useEffect(() => {
        document.title =  "Login"
    }, [])

    const [initialValues, setInitialValues] = useState({
        email: '',
        password: ''
    })

    const onSubmit = async (request, onSubmitProps) => {

        // console.log('Form request =', request);
        var response = await postCall(LOGIN, request)
        if (response?.code === 200) {
            await createAuditLog({...props, user:response?.data?.user}, { hit_map:'login submit successful', page:'login', page_url:window.location.href, user_agent:userAgent, api_path: process.env.REACT_APP_API_BASE_URL+LOGIN, api_request: JSON.stringify({...request, password:'********'}), api_response: JSON.stringify(response) })
            toast.success(response?.message?.[0])
            
            setTimeout(() => {
                window.location.reload();
            }, 100)
            
            props.login(response?.data)
            onSubmitProps.setSubmitting(false)
        } else {
            await createAuditLog(props, { hit_map:'login submit failed', page:'login', page_url:window.location.href, user_agent:userAgent, api_path: process.env.REACT_APP_API_BASE_URL+LOGIN, api_request: JSON.stringify({...request, password:'********'}), api_response: JSON.stringify(response) })

            toast.error(response?.message?.[0], {
                position: toast.POSITION.TOP_CENTER
            })
            onSubmitProps.setSubmitting(false)
        }
    }

    const validationSchema = Yup.object({
        email : Yup.string().required('Email is required!').email('Email format is invalid!'),
        password : Yup.string().required('Password is required!'),
    })

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })


    return (
        <Fragment >
            <div className="d-flex flex-column flex-lg-row-fluid py-10" style={{ 
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/media/login/bg.svg)`, 
                backgroundSize: 'cover', 
                minHeight: '100vh' 
            }}>
                <div className="d-flex flex-center flex-column flex-column-fluid">
                    <div className="w-lg-500px p-10 p-lg-15 mx-auto">
                        <div className="card  shadow-sm" style={{background: "rgba(4, 4, 4, 0.3)"}}>
                            <div className="card-body">
                                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >

                                {
                                    formik => {
                                        return (
                                            <Form className="form w-100">
                                                <div className="text-center mb-5">
                                                    <img alt="Logo" src={`${process.env.PUBLIC_URL}/assets/media/logos/logo.png`} className="h-50px " />
                                                </div>
                                                <div className="text-center mb-5">
                                                    <h1 className="mb-3 text-white" style={{  fontFamily: 'neuropol' }}>Login</h1>
                                                </div>
                                                <div className="fv-row mb-10">
                                                    {/* <label className="form-label fs-6 fw-bolder text-dark">Email</label> */}
                                                    <Field type="text" name="email" className="form-control form-control-sm" placeholder="Your Email Address" />
                                                    <ErrorMessage name="email" component={TextErrorMessage2} />
                                                </div>
                                                <div className="fv-row mb-10">
                                                    {/* <div className="d-flex flex-stack mb-2">
                                                        <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                                                    </div> */}
                                                    <Field type="password" name="password" className="form-control form-control-sm" placeholder="Password"/>
                                                    <ErrorMessage name="password" component={TextErrorMessage2} />
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-success w-100 mb-5"
                                                        disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
                                                    >
                                                        {
                                                            !formik.isSubmitting ?
                                                                <span className="indicator-label">Continue</span>
                                                                :
                                                                <span className="indicator-label">Processing...</span>
                                                        }
                                                    </button>
                                                </div>
                                            </Form>
                                            )
                                        }
                                    }
                                </Formik>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

const mapDispatchToProps = (dispatch) => ({
    login: (data) => dispatch(SET_USER_DATA(data)),
})

export default connect(null, mapDispatchToProps)(Login);