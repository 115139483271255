// User Route
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const CHANGE_PASSWORD = '/changePassword'
export const UPDATE_PROFILE = '/profileUpdate'
export const ME = '/me'


// users route
export const USERS = '/getAllUsers_p'
export const CREATE_USER = '/createUser'
export const SINGLE_USER_INFO = '/getUser'
export const UPDATE_USER = '/updateUser'

// roles route
export const ROLES = '/role/getAllRoles'
export const ROLES_P = '/role/getAllRoles_p'
export const ROLES_ALL = '/role/getAllRoles'
export const ROLE_DELETE = '/role/deleteRole'
export const SINGLE_ROLE_INFO = '/role/getRole'
export const UPDATE_ROLE = '/role/updateRole'
export const CREATE_ROLE = '/role/createRole'

// permission routes
export const PERMISSION_ALL = '/permission/getAllpermissions'
export const PERMISSION_P = '/permission/getAllPermissions_p'
export const CREATE_PERMISSION = '/permission/createPermission'
export const SINGLE_PERMISSION_INFO = '/permission/getPermission'
export const UPDATE_PERMISSION = '/permission/updatePermission'

// permission modules routes
export const MODULE_ALL = '/module/getAllModules'


// List
export const USER_LIST = '/list/getAllUserList'

// Audit log
export const CREATE_AUDIT_LOG = '/audit-log/createAuditLog'
export const ALL_AUDIT_LOG_P = '/audit-log/getAllAuditLog_p'


// Dasboard routes
export const DASHBOARD_DATA = '/dashboard/dashboard-data'

// Report routes

// Date wise Report routes
export const DATE_WISE_REPORT_DATA = '/report/date-wise-report/report-data'

// Date wise Report routes
export const OPERATOR_WISE_DETAILS_REPORT_DATA = '/report/operator-wise-details-report/report-data'

// Leaderboard routes
export const LEADERBOARD_DATA = '/leaderboard/report-data'