import React, { useEffect, useState, Fragment} from 'react';
import { connect } from 'react-redux'
import Validation  from '../components/Forms/Validation.js';
import { postCall, getCall } from '../api/apiService'
import { ME, UPDATE_PROFILE } from '../api/apiPath'
import { toast } from 'react-toastify'
import { Formik, useFormik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TextErrorMessage from '../components/Error/TextErrorMessage'

function Test(props) {

    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
    })

    const onSubmit = async (values, onSubmitProps) => {
        // console.log('Form values =', values);
        // var response = await postCall(UPDATE_PROFILE, values, props.user.access_token)
        // if (response?.code === 200) {
        //     getMe()
        //     toast.success(response?.message?.[0])
        //     onSubmitProps.setSubmitting(false)
        // } else {
        //     toast.error(response?.message?.[0])
        //     onSubmitProps.setSubmitting(false)
        // }

    }

    const validationSchema = Yup.object({
        name : Yup.string().required('Name is required!')
    })
    

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    // console.log('Form props =', formik);
    // console.log('Form errors =', formik.errors);
    // console.log('Visited fields =', formik.touched);

    return (
        <Fragment>

                <section className="login-block d-block" style={{ background: "none"}}  >
                {/* <!-- Container-fluid starts --> */}
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">

                            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize >

                                {
                                    formik => {
                                        // console.log('formik props = ', formik)
                                        return (
                                            <Form className="md-float-material form-material">
                                                <div className="auth-box card shadow" style={{borderRadius: "3%"}}>
                                                    <div className="card-block">
                                                        <div className="row m-b-20">
                                                            <div className="col-md-12">
                                                                <h3 className="text-center">Form Testing</h3>
                                                            </div>
                                                        </div>
                                                    
                                                        <div className="form-group form-primary">
                                                            <Field type="text" name="name" className="form-control form-control-sm" placeholder="Name"/>
                                                            <ErrorMessage name="name" component={TextErrorMessage} />
                                                        </div>

                                                        <div className="form-group form-primary">
                                                            <Field type="text" name="email" className="form-control form-control-sm" required={true}  placeholder="Email" readOnly/>
                                                            <ErrorMessage name="email" component={TextErrorMessage} />
                                                        </div>
                                                        
                                                        <div className="row m-t-30">
                                                            <div className="col-md-12">
                                                                <button type="submit" className="btn btn-info btn-md btn-block waves-effect waves-light text-center m-b-20" style={{ background:"#03A9F4" }}
                                                                    disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
                                                                >Save</button>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }
                                }

                            </Formik>


                            {/* <!-- end of form --> */}
                        </div>
                        {/* <!-- end of col-sm-12 --> */}
                    </div>
                    {/* <!-- end of row --> */}
                </div>
                {/* <!-- end of container-fluid --> */}
            </section>


        </Fragment>
    )
}



const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

export default connect(mapStateToProps)(React.memo(Test));

