import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../redux/action'
import { permissionsResets, createAuditLog, userAgent, numberFormat } from '../components/Helpers/CommonHelpers'
import { toast } from 'react-toastify'
import { DASHBOARD_DATA } from '../api/apiPath'
import { postCall } from '../api/apiService'
import PieChartComponentDashboardTop5Leaderboard from '../components/Chart/PieChartComponentDashboardTop5Leaderboard'
import BarChartComponentDashboardLast7DaysHitCount from '../components/Chart/BarChartComponentDashboardLast7DaysHitCount'

function Dashboard(props) {
    const breadcrumb = {
        pageTitle: 'Dashboard',
        currentPath: '/',
        layers: [
            {
                title: 'Home',
                link: '/',
            }
        ],

    }
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props?.user?.force_password != 0) {
            props.history.push('/profile')
        }
        permissionsResets(props)
        props.setPageBreadcrumb(breadcrumb)
        dashboard_data_process()
        createAuditLog(props, { hit_map: 'page', page: breadcrumb?.pageTitle, page_url: window.location.href, user_agent: userAgent })
    }, [])

    const dashboard_data_process = async (e) => {
        var response = await postCall(DASHBOARD_DATA, null, props.user.access_token)
        if (response?.code === 200) {
            setData(response?.data)
        } else {
            toast.error(response?.message?.[0])
            setData(null)
        }
    }

    const badgeGetter = (i) => {
        if ([1, 2, 3].includes(i)) {
            return <img alt="Rank" src={`assets/media/rank/rank-${i}.png`} className="h-lg-25px h-40px" />;
        } else {
            return <span className="badge badge-circle badge-light h-30px w-30px">{i + 1}</span>;
        }
    }


    return (
        <React.Fragment>

            <div className='row'>
                <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-4 col-lg-4 col-xl-4 col-xxl-3 mb-md-5 mb-xl-12 mx-3">
                    <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{numberFormat(data?.summary?.today_all_operator_hit || 0)}</span>
                            </div>
                            <span className="text-gray-400 pt-1 fw-bold fs-6">Today Total Hit Count</span>
                        </div>
                    </div>
                </div>
                <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-4 col-lg-4 col-xl-4 col-xxl-3 mb-md-5 mb-xl-12 mx-3">
                    <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{numberFormat(data?.summary?.last_7_days_total || 0)}</span>
                            </div>
                            <span className="text-gray-400 pt-1 fw-bold fs-6">Last 7 Days Total Hit Count</span>
                        </div>
                    </div>
                </div>
                <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-4 col-lg-4 col-xl-4 col-xxl-3 mb-md-5 mb-xl-12 mx-3">
                    <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">{numberFormat(data?.summary?.all_operator_total_hit || 0)}</span>
                            </div>
                            <span className="text-gray-400 pt-1 fw-bold fs-6">Total Hit Count</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-6 col-lg-6 col-xl-6 col-xxl-12 mb-md-5 mb-xl-12 mx-3">
                    <div className="card-body d-flex align-items-end px-0 pb-0">
                        <BarChartComponentDashboardLast7DaysHitCount data={data?.last_7_days_of_hit_count?.graph} />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10 mx-3">
                    <div className="card-header pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark"><i className="la la-pie-chart"></i> Operator Wise Hit Count</span>
                        </h3>
                    </div>
                    <div className="card-body d-flex align-items-end px-0 pb-0">
                        <PieChartComponentDashboardTop5Leaderboard data={data?.operator_wise_hit_count} />
                    </div>
                </div>

                <div className="card card-flush h-md-50 mb-5 mb-xl-10 col-md-5 col-lg-5 col-xl-5 col-xxl-5 mb-md-5 mb-xl-10 mx-3">
                    <div className="card-header pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark"><i className="la la-sort-amount-asc menu-icon"></i> Leaderboard: Top 5</span>
                        </h3>
                    </div>
                    <div className="card-body d-flex align-items-end px-0 pb-0">
                        <table className="table table-hover table-rounded table-striped border gy-7 gs-7">

                            <tbody >
                                {
                                    data?.leaderboard_top_5?.map((row, i) => {
                                        return (
                                            <tr key={'row-' + i}>
                                                <td>{badgeGetter(i + 1)}</td>
                                                <td>{row?.endUserId}</td>
                                                <td>{numberFormat(row?.no_of_hit)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}


const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
