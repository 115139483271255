import React, { useState, Fragment } from 'react';

const Checkbox = ({ label, value, onChange, disabled=false, className='' }) => {
    const [checked, setChecked] = useState(false);
  
    const handleChange = () => {
      setChecked(!checked);
    };

    const cb_element = 'cb_'+getRandomArbitrary(100,1000)

    function getRandomArbitrary(min, max) {
      return Math.random() * (max - min) + min;
    }
    
  
    return (
      <>
          <div className={`form-check form-check-custom form-check-primary form-check-solid form-check-sm `+className}>
              <input className="form-check-input" type="checkbox" checked={value} onChange={onChange} id={cb_element} style={{ "cursor" : "pointer" }} disabled={disabled}/>
              <label className="form-check-label" htmlFor={cb_element}style={{ "cursor" : "pointer" }}>{label}</label>
          </div>

          {/* <div className="custom-control custom-checkbox mb-2 mx-1">
              <input className="custom-control-input mx-2" type="checkbox" checked={value} onChange={onChange} id={cb_element} style={{ "cursor" : "pointer" }} disabled={disabled}/>
              <label className="custom-control-label" htmlFor={cb_element}style={{ "cursor" : "pointer" }}>{label}</label>
          </div> */}
      </>
    );
};


export default Checkbox
