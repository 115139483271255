import React, {Fragment} from 'react'

function TextErrorMessage(props) {
    return (
        <Fragment>
            <span style={{ width: '100%',marginTop: '.5rem', fontSize: '.925rem', color: '#f06445' }}>{props.children}</span>
        </Fragment>
    )
}

export default TextErrorMessage
