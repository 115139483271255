import React, { Fragment } from 'react';
import { getCurrentYear } from '../components/Helpers/CommonHelpers'

function Footer() {
    return (
        <Fragment>
            <div id="take-available-space-for-footer "></div>
            {/*begin::Footer*/}
            <div className="footer py-4 d-flex flex-lg-column shadow-lg bg-light-info card ms-desktop-73px" id="kt_footer">
                {/*begin::Container*/}
                <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                    {/*begin::Copyright*/}
                    <div className="text-dark order-2 order-md-1">
                        <span>Copyright &copy; {getCurrentYear()} | Wintel Limited | All Rights Reserved.</span>
                    </div>
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Footer*/}
        </Fragment>
    )
}

export default Footer;