import React, { useEffect } from 'react';
import Router from './route/route'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import { createBrowserHistory } from "history";

// Import All CSS
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "@icon/icofont/icofont.css";


// Import js
function App() {
  useEffect(() => {

    // if (process.env.REACT_APP_ENV) {
      console.log('=============process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);
    // }
    
  }, [])

  const history = createBrowserHistory();

  


  return (
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </PersistGate>
      </Provider>
    </React.Fragment>
  );
}


export default App;
