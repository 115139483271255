import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { postCall } from '../api/apiService.js'
import { DATE_WISE_REPORT_DATA } from '../api/apiPath.js'
import { toast } from 'react-toastify'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../redux/action.js'
import { permissionsResets, createAuditLog, userAgent, badge_colors, numberFormat } from '../components/Helpers/CommonHelpers.js'

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import Submitbutton from '../components/Buttons/Submitbutton'

function DateWiseReport(props) {

    const breadcrumb = {
        pageTitle: 'Date wise Report',
        currentPath: '/date-wise-report',
        layers: [
            {
                title: 'Home',
                link: '/'
            },
            {
                title: 'Report'
            },
            {
                title: 'Date wise Report',
                default: 1
            }
        ]
    }

    const formInitial = {
        filter: {
            start_date: null,
            end_date: null,
        },
    }
    const [formData, setFormData] = useState(formInitial)

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }


    const [reportData, setReportData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [noDataFound, setNoDataFound] = useState(false)

    const getReportData = async (e) => {
        setNoDataFound(false)

        setIsLoading(true)
        setReportData([])
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        let request = {...formData?.filter}
        var response = await postCall(DATE_WISE_REPORT_DATA, {...request}, props.user.access_token)
        if (response?.code === 200) {
            setReportData(response?.data?.data);
            setIsLoading(false)
            if (response?.data?.data?.length == 0) { setNoDataFound(true); }
            else setNoDataFound(false)
        } else {
            toast.error(response?.message?.[0])
            setReportData([]);
            setIsLoading(false)
            setNoDataFound(true);
        }
    }




    useEffect(() => {
        permissionsResets(props)
        createAuditLog(props, { log_type_id: 3, hit_map: 'page', page: breadcrumb?.pageTitle, page_url: window.location.href, user_agent: userAgent })
        props.setPageBreadcrumb(breadcrumb)

    }, [])

    const clear = () => {
        setFormData(formInitial)
    }

    const reset = () => {
        setFormData(formInitial)
    }

    return (
        <Fragment>

            <div className="card col-12">

                <div className="card-header pt-5">
                    <form className="w-100" onSubmit={getReportData} >
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField {...props} className="w-100" />}
                                        label="Start Date"
                                        value={formData?.filter?.start_date}
                                        onChange={(newValue) => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                filter: {
                                                  ...prev.filter,
                                                  start_date: moment(newValue).format('yy-MM-DD')
                                                }
                                              }))
                                        }}
                                        inputFormat="yyyy-MM-dd"
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-5">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField {...props} className="w-100" />}
                                        label="End Date"
                                        value={formData?.filter?.end_date}
                                        onChange={(newValue) => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                filter: {
                                                  ...prev.filter,
                                                  end_date: moment(newValue).format('yy-MM-DD')
                                                }
                                              }))
                                        }}
                                        inputFormat="yyyy-MM-dd"
                                    />
                                </LocalizationProvider>
                            </div>



                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <Submitbutton />
                                <button type="button" className="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger" onClick={reset}>Reset</button>

                            </div>


                        </div>

                    </form>

                </div>

                <div className="card-block  py-5 px-2">

                    {
                        isLoading || noDataFound ?
                            <div className="row col-12" style={{ marginTop: "100px" }}>
                                {
                                    isLoading ?
                                        <div className="spinner-border text-primary mx-auto " style={{ width: "70px", height: "70px" }} alt="Loading..." ></div>
                                        : null
                                }
                                {
                                    noDataFound ?
                                        <div className="mx-auto">
                                            <label className="badge badge-inverse-warning label-lg label" style={{ fontSize: "18px" }} >No Data Found!</label>
                                        </div>
                                        : null
                                }
                            </div> : null
                    }


                    {
                        reportData?.length > 0 ?
                            <Fragment>

                                <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th>Serial</th>
                                            <th>operator</th>
                                            <th>Receive Date</th>
                                            <th>total hit</th>
                                            <th>unique participate count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reportData.map((row, i) => {
                                                return (
                                                    <tr key={'row-permission-' + i}>
                                                        <td>{i + 1}</td>
                                                        <td>{row?.operator}</td>
                                                        <td>{row?.receive_date}</td>
                                                        <td>{numberFormat(row?.['total hit'])}</td>
                                                        <td>{numberFormat(row?.['unique participate count'])}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>


                            </Fragment>
                            : null
                    }
                </div>
            </div>



        </Fragment>
    )
}



const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DateWiseReport));

