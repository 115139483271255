import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { postCall } from '../api/apiService.js'
import { LEADERBOARD_DATA } from '../api/apiPath.js'
import { toast } from 'react-toastify'
import { SET_BREADCRUMB_DATA, SET_USER_DATA } from '../redux/action.js'
import { Link } from 'react-router-dom';
import { permissionsResets, createAuditLog, userAgent, numberFormat } from '../components/Helpers/CommonHelpers.js'
import Select from 'react-select'
import Badge from '../components/Badges/Badge.js';

function Leaderboard(props) {

    const breadcrumb = {
        pageTitle: 'Leaderboard',
        currentPath: '/leaderboard',
        layers: [
            {
                title: 'Home',
                link: '/'
            },
            {
                title: 'Leaderboard',
                default: 1
            }
        ]
    }


    const [leaderboard, setLeaderboard] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [noDataFound, setNoDataFound] = useState(false)

    const getLeaderboardData = async (e) => {
        setNoDataFound(false)

        setIsLoading(true)
        setLeaderboard(null)
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        var response = await postCall(LEADERBOARD_DATA, null, props.user.access_token)
        if (response?.code === 200) {
            console.log(response?.data);
            setLeaderboard(response?.data);
            setIsLoading(false)
            if (response?.data?.data?.length == 0) { setNoDataFound(true); }
            else setNoDataFound(false)
        } else {
            toast.error(response?.message?.[0])
            setLeaderboard({});
            setIsLoading(false)
            setNoDataFound(true);
        }
    }

    useEffect(() => {
        permissionsResets(props)
        createAuditLog(props, { log_type_id: 3, hit_map: 'page', page: breadcrumb?.pageTitle, page_url: window.location.href, user_agent: userAgent })
        props.setPageBreadcrumb(breadcrumb)

        getLeaderboardData()
    }, [])


    return (
        <Fragment>


            <div className="card col-12">

                <div className="card-block  py-5 px-2">

                    {
                        isLoading || noDataFound ?
                            <div className="row col-12" style={{ marginTop: "100px" }}>
                                {
                                    isLoading ?
                                        <div className="spinner-border text-primary mx-auto " style={{ width: "70px", height: "70px" }} alt="Loading..." ></div>
                                        : null
                                }
                                {
                                    noDataFound ?
                                        <div className="mx-auto">
                                            <label className="badge badge-inverse-warning label-lg label" style={{ fontSize: "18px" }} >No Data Found!</label>
                                        </div>
                                        : null
                                }
                            </div> : null
                    }

                    {
                        leaderboard?.leaderboard_top_3?.length > 0 ?
                            <Fragment>

                                <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center me-3 mt-12">
                                                    <img alt="rank" src='assets/media/rank-2/rank-2.png' className='me-3 w-60px' />
                                                    <div className="flex-grow-1">
                                                        <span className="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">{leaderboard?.leaderboard_top_3[1]?.endUserId}</span>
                                                        <span className="text-gray-400 fw-bold d-block fs-6">{numberFormat(leaderboard?.leaderboard_top_3[1]?.no_of_hit)}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center me-3">
                                                    <img alt="rank" src='assets/media/rank-2/rank-1.png' className='me-3 w-60px' />
                                                    <div className="flex-grow-1">
                                                        <span className="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">{leaderboard?.leaderboard_top_3[0]?.endUserId}</span>
                                                        <span className="text-gray-400 fw-bold d-block fs-6">{numberFormat(leaderboard?.leaderboard_top_3[0]?.no_of_hit)}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center me-3 mt-12">
                                                    <img alt="rank" src='assets/media/rank-2/rank-3.png' className='me-3 w-60px' />
                                                    <div className="flex-grow-1">
                                                        <span className="text-gray-800 text-hover-primary fs-5 fw-bolder lh-0">{leaderboard?.leaderboard_top_3[2]?.endUserId}</span>
                                                        <span className="text-gray-400 fw-bold d-block fs-6">{numberFormat(leaderboard?.leaderboard_top_3[2]?.no_of_hit)}</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Fragment>
                            : null
                    }


                    {
                        leaderboard?.leaderboard_top_4_to_100?.length > 0 ?
                            <Fragment>

                                <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th>Rank</th>
                                            <th>User</th>
                                            <th>Total Hit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            leaderboard?.leaderboard_top_4_to_100.map((row, i) => {
                                                return (
                                                    <tr key={'row-leaderboard-2-' + i}>
                                                        <td>{i + 4}</td>
                                                        <td>{row?.endUserId}</td>
                                                        <td>{numberFormat(row?.no_of_hit)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Fragment>
                            : null
                    }
                </div>
            </div>

        </Fragment>
    )
}



const mapStateToProps = (state) => ({
    user: state.user,
    roles: state.roles,
    permissions: state.permissions
});

const mapDispatchToProps = (dispatch) => ({
    setPageBreadcrumb: (data) => dispatch(SET_BREADCRUMB_DATA(data)),
    me: (data) => dispatch(SET_USER_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Leaderboard));

