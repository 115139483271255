import React from 'react'

function Table(props) {

    return (
        <>
            {
                props?.data?.length > 0 ?
                    <>

                        <table className={props?.classList || "table table-hover table-rounded table-striped table-row-dashed table-row-gray-300 border gy-7 gs-7" }>
                            <thead>
                                <tr className={"text-start text-muted fw-bolder fs-7 text-uppercase gs-0 "+(props?.stickyHeader? 'sticky-header' : '')} >
                                    {
                                        Object.keys(props?.data[0]).map((key, i) => (
                                            <th  key={'th-'+i}  >{key}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            
                            <tbody>
                                {
                                    props?.data?.map((row, i) => {
                                        return (
                                            <tr key={'row-'+i}>
                                                {
                                                    Object.values(props?.data[i])?.map((column, j) => (
                                                        <td key={'td-'+i+'-'+j}>{column}</td>
                                                    ))
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            
                        </table>

                        
                    </>
                : null
            }
        </>
    )
}

export default Table

